/* BlogPostPage.css */

.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60vh;
  }
  
  .not-found-icon {
    color: #dc3545;
    font-size: 5rem;
    margin-bottom: 0.5rem;
  }
  
  .not-found-message {
    font-size: 1.5rem;
    color: #6c757d;
  }

  .go-back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s;
  }
  
  .go-back-button:hover {
    background-color: #0056b3;
  }
  
  .go-back-button svg {
    margin-right: 5px;
  }
  