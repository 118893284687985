/* src/pages/NotFound/NotFound.css */
.not-found-container {
    text-align: center;
    padding-top: 5%;
    color: #2e2e2e;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f8f9fa;
  }
  
  .logo {
    max-width: 200px; /* Make the logo stand out more */
    margin-bottom: 2rem;
  }
  
  .not-found-icon {
    font-size: 8rem; /* Increase the size of the icon for emphasis */
    margin-bottom: 1rem;
    color: #ff6b6b; /* A color that indicates a warning or error */
  }
  
  .not-found-container h1 {
    font-size: 8rem; /* Large size for the error code */
    margin: 0;
    color: #333;
    margin-bottom: 0.25em;
  }
  
  .not-found-container p {
    font-size: 1.25em; /* Large enough to be readable on all devices */
    max-width: 600px; /* Set a max-width for better readability */
    margin: 0 auto 1.5rem; /* Center the text block */
  }
  
  .home-button {
    padding: 1rem 2rem;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 50px; /* Fully rounded edges for a modern look */
    cursor: pointer;
    font-size: 1.25em; /* Easy to read on all devices */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
  }
  
  .home-button:hover,
  .home-button:focus {
    background-color: #003973; /* Slightly darker on hover for feedback */
    outline: none; /* Remove the focus outline to maintain the design */
  }
  
  @media (max-width: 768px) {
    .not-found-container h1 {
      font-size: 5rem;
    }
  
    .not-found-icon {
      font-size: 5rem;
    }
  
    .home-button {
      padding: 0.75rem 1.5rem;
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .not-found-container {
      padding-top: 10%;
      padding-bottom: 10%;
    }
  
    .not-found-container h1 {
      font-size: 4rem;
    }
  
    .not-found-icon {
      font-size: 4rem;
    }
  
    .home-button {
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
    }
  }
  