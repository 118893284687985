:root {
  --text-color: black;
  --secondary-background: #f8f8f8;
  --primary-color:#3B76D6;
  --darkerPrimary-color: #3B76D6;
  --secondary-color: #6AD4DD;
  --accent-color:#7AA2E3;
  --neutral-color: #F8F6E3;
  --hover-color: #264e90;
  --background-color: white;
  --glassmorphic: rgba(255, 255, 255, 0.25);
  --box-shadow: rgba(0, 0, 0, 0.1);
  --button-color:#3B76D6;
  --webDarker-color: #3B76D6;
  --textGray-color: #8E8E93;
}
